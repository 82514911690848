<template>
  <div class="guide">
    <Normaltop :url="qiniuUrl" title="名企人才库" en="Talent Pool"></Normaltop>
    <div class="guide-cont">
      <!-- <ul class="guide-menu flex">
        <li @click="goAssignBlock('block0',50)">名企人才库</li>
        <li @click="goAssignBlock('block1',50)">合作企业</li>
      </ul> -->
      <div class="part" ref="block0">
        <el-row :gutter="60">
          <el-col :span="11" :push="1">
            <img src="../assets/images/peoples/img1.png" alt srcset />
          </el-col>
          <el-col :span="13" :pull="1">
            <div class="cont-box">
              <div class="cont">
                <h3 class="part-h3">名企人才库</h3>
                <p class="part-cont">
                  网培师能力水平评价项目由中国商业联合会和上海现代服务业联合会互联网科创服务专委会分别批准，面向广大的在线教育培训从业人员提供网络培训能力的测评认证。项目采用网班教育的《网培师教育培训及评测标准》体系，并与上海市培训协会、上海市人才服务行业协会，及多家头部教育机构、大专院校等合作，面向国内的在线教育培训工作者提供技能培训、评测认证、上岗就业等系列服务。
                </p>
                <p class="part-cont">
                  网培师项目并得到联合国训研所UNITAR中国中心的认可并达成正式合作，参与培训及认证的网培师可申请进入联合国训研所的官方人才库。
                </p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div ref="block1">
        <div class="part">
          <h2 class="part-title">合作机构</h2>
          <img src="../assets/images/peoples/img2.jpg" alt srcset />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop'
export default {
  name: 'Certificate',
  data() {
    return {
      qiniuUrl: this.$qiniuUrl + 'peoples/banner.png',
    }
  },
  components: { Normaltop },
  created() {
    this.init()
  },
  methods: {
    init() {},
    goAssignBlock(el, speed) {
      let windowH = window.innerHeight //浏览器窗口高度
      let h = this.$refs[el].offsetHeight //模块内容高度
      let t = this.$refs[el].offsetTop //模块相对于内容顶部的距离
      let top = t - (windowH - h) / 2 + 200 //需要滚动到的位置，若改为 t 则滚动到模块顶部位置，此处是滚动到模块相对于窗口垂直居中的位置
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop //滚动条距离顶部高度
      let currentTop = scrollTop //默认滚动位置为当前滚动条位置，若改为0，则每次都会从顶部滚动到指定位置
      let requestId
      //采用requestAnimationFrame，平滑动画
      function step() {
        //判断让滚动条向上滚还是向下滚
        if (scrollTop < top) {
          if (currentTop <= top) {
            //   window.scrollTo(x,y) y为上下滚动位置
            window.scrollTo(0, currentTop)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向下滚动
          currentTop += speed
        } else {
          if (top <= currentTop) {
            //注：此处 - speed 是解决居中时存在的问题，可自行设置或去掉
            window.scrollTo(0, currentTop - speed)
            requestId = window.requestAnimationFrame(step)
          } else {
            window.cancelAnimationFrame(requestId)
          }
          //向上滚动
          currentTop -= speed
        }
      }
      window.requestAnimationFrame(step)
    },
  },
}
</script>
<style lang="less" scoped>
.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  .guide-cont {
    width: 70%;
    min-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding: 70px 0;
    .guide-menu {
      height: 35px;

      li {
        margin-right: 30px;
        font-size: 24px;
        color: #000005;
        cursor: pointer;
        &:hover {
          border-bottom: 2px solid #f5002e;
        }
      }
    }
    .cont-box {
      padding-right: 20px;
      background: #960f23;
      border-radius: 6px;
      margin: 20px 0;
      .cont {
        background: #fff;
        padding: 20px 40px;
      }
    }
    .part {
      width: 60%;
      min-width: 1100px;
      margin: 0 auto;
      position: relative;
      padding-top: 50px;
      .methods {
        padding: 25px 0;
      }
      .part-title {
        color: #000005;
        font-weight: bold;
        font-size: 36px;
        padding-bottom: 50px;
      }
      .part-h3 {
        font-size: 30px;
      }
      .part-cont {
        padding-top: 16px;
        color: #333;
        line-height: 30px;
      }
      .part-indent {
        text-indent: 20px;
      }

      .icon {
        width: 120px;
        margin-right: 30px;
      }
      .form-box {
        width: 620px;
        margin: 0 auto;
        /deep/.el-card__body {
          padding: 30px 55px 30px 42px;
        }
        .code-box {
          width: 200px;
        }
        .getcode-btn {
          width: 200px;
          border-color: #e10133;
          color: #e10133;
          margin-left: 23px;
        }
        /deep/.no-label .el-form-item__content {
          margin: 0 !important;
        }
      }
    }
    .table-box {
      border-top: 7px solid #e10133 !important;
      border-bottom: 7px solid #e10133 !important;
    }
    .table {
      // border:4px solid #E8EBEE;

      color: #333333;
      font-size: 18px;
      line-height: 36px;

      td {
        padding: 35px 25px;
        border: 4px solid #e8ebee;
        border-collapse: collapse;
      }
      h3 {
        font-weight: bolder;
        font-size: 24px;
      }
    }
  }
}
</style>
